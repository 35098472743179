.home-toolbar-title {
    margin-left: 30px;
    font-size: 24px;
    font-weight: bold;
}

.home-content {
    /*padding: 40px;*/
}

.home-section-header {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
}

.home-demo-row {
    margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
    .home-content {
        padding: 20px;
    }
}